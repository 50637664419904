import React from "react";
import ConvertKitForm from 'convertkit-react';

const Signup = () => {
  const config = {
    formId: 2454578,
    template:'clare',
    emailPlaceholder: 'Enter your email address',
    submitText: 'Subscribe',
    hideName: true,
  }

  return (
    <ConvertKitForm {...config} />
  )
}
export default Signup
